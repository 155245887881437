body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--dark);
  color: var(--light);
}

:root {
  --primary: #ffcb05;
  --secondary: #00b487;
  --tertiary: #2390e8;
  --dark: #1e1e1e;
  --light: #eeeeee;
  --dynamic-button-color: var(--primary);
  --success: #4a9c54;
  --error: #ce4040;
}

@font-face {
  font-family: "PokemonSolid";
  src: local("PokemonSolid"), url(./fonts/PokemonSolid.ttf) format("truetype");
}

header {
  margin: 2rem 0;
}

a {
  text-decoration: none;
  color: var(--primary);

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }
}

legend {
  font-size: 1.25rem;
  font-weight: 600;
  margin-left: -1.75rem;
  padding: 0 1rem 0.25rem 1rem;
}

h1 {
  font-family: PokemonSolid;
  letter-spacing: 0.25rem;
  color: var(--primary);
  -webkit-text-stroke: 1.2px var(--tertiary);
  line-height: 3.5rem;
  margin: 1rem;
}

section {
  margin: 0 0 1.5rem 0;
}

.typeDisplayContainer {
  background: rgba(255, 255, 255, 0.1);
  width: 338px;
  margin-left: auto;
  margin-right: auto;
  padding: 0.25rem 1rem 1rem;
  border-radius: 8px;

  button {
    color: #eeeeee;
    margin: 0 0.5rem;
    padding: 0.75rem;
    border: none;
    font-weight: 600;
    border-radius: 4px;
    background: var(--secondary);
    box-shadow: 0 4px 0px
      color-mix(
        in hsl decreasing hue,
        var(--secondary) 20%,
        var(--secondary) 40%
      );
    transition: all;

    &:hover {
      cursor: pointer;
      transform: translateY(2px);
      box-shadow: 0 2px 0px
        color-mix(
          in hsl decreasing hue,
          var(--secondary) 20%,
          var(--secondary) 40%
        );
    }

    &:last-of-type {
      background: var(--tertiary);
      box-shadow: 0 4px 0px
        color-mix(
          in hsl decreasing hue,
          var(--tertiary) 20%,
          var(--tertiary) 40%
        );

      &:hover {
        cursor: pointer;
        transform: translateY(2px);
        box-shadow: 0 2px 0px
          color-mix(
            in hsl decreasing hue,
            var(--tertiary) 20%,
            var(--tertiary) 40%
          );
      }
    }
  }

  span {
    font-size: 1.75rem;
    font-weight: 700;
  }
}

.results-container {
  margin: 0 2rem 4rem;

  .results {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
    gap: 1rem;
    margin: 1rem 0;
  }
}

.typeOptionInputsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto 2rem auto;
  padding: 0 1rem;
  gap: 2rem;
}

.typeOptionInputs {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.typeOptionButton {
  padding: 1rem;
  border: none;
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  border: 2px solid transparent;

  &:hover {
    cursor: pointer;
  }
}

.typeOptionDisplay {
  padding: 0.5rem 1rem;
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  border: 4px solid transparent;
  margin-bottom: 0.5rem;
}

.btn-selected {
  box-shadow: 0 0 8px inset var(--dark);
  filter: brightness(1.2);
  border: 2px solid white;
}

.playAgainButton {
  color: var(--dark);
  margin: 0 0.5rem;
  padding: 0.75rem;
  border: none;
  font-weight: 600;
  border-radius: 4px;
  background: var(--primary);
  box-shadow: 0 4px 0px
    color-mix(in hsl decreasing hue, var(--primary) 20%, var(--primary) 40%);
  transition: all;

  &:hover {
    cursor: pointer;
    transform: translateY(2px);
    box-shadow: 0 2px 0px
      color-mix(in hsl decreasing hue, var(--primary) 20%, var(--primary) 40%);
  }
}

footer {
  margin: 0 2rem 1rem;
}

fieldset {
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.border-success {
  border: 4px solid var(--success);
}

.border-faulty {
  border: 4px solid var(--error);
}
